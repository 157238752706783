import { UseFormRegisterReturn } from 'react-hook-form';
import { generateClass } from '@common/component.utils';
import B from '@components/button';
import ErrorMessage from '@components/ErrorMessage';

type CommonProps = {
    type?: string;
    id?: string;
    name?: string;
    className?: string;
    readOnly?: boolean;
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
};

type FSearchInputProps = CommonProps & {
    iconOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type RegisterProps = CommonProps & {
    register: UseFormRegisterReturn;
    errorMessage?: string;
};

type RSearchInputProps = RegisterProps & {
    iconOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

function FInput({
    type = 'text',
    id,
    name,
    className,
    onChange,
    onClick,
    onKeyUp,
    readOnly = false,
    placeholder,
    value,
}: CommonProps) {
    const tableClass = generateClass('com_input', className);
    return (
        <input
            className={tableClass}
            type={type}
            id={id}
            onChange={onChange}
            name={name}
            onClick={onClick}
            onKeyUp={onKeyUp}
            readOnly={readOnly}
            placeholder={placeholder}
            value={value}
        />
    );
}

function FSearchInput({
    type = 'text',
    id,
    name,
    className,
    onChange,
    onClick,
    onKeyUp,
    readOnly = false,
    placeholder,
    value,
    iconOnClick,
}: FSearchInputProps) {
    const tableClass = generateClass('com_input search', className);
    return (
        <>
            <input
                className={tableClass}
                type={type}
                id={id}
                onChange={onChange}
                name={name}
                onClick={onClick}
                onKeyUp={onKeyUp}
                readOnly={readOnly}
                placeholder={placeholder}
                value={value}
            />
            <B.Search onClick={iconOnClick} />
        </>
    );
}

function RInput({
    type = 'text',
    id,
    className,
    onClick,
    register,
    errorMessage,
    readOnly = false,
    placeholder,
}: RegisterProps) {
    const tableClass = generateClass('com_input', className);
    return (
        <>
            <input
                type={type}
                id={id}
                placeholder={placeholder}
                className={tableClass}
                onClick={onClick}
                readOnly={readOnly}
                {...register}
            />
            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
        </>
    );
}

function RSearchInput({
    type = 'text',
    id,
    className,
    onClick,
    onKeyUp,
    register,
    errorMessage,
    readOnly = false,
    iconOnClick,
}: RSearchInputProps) {
    const tableClass = generateClass('com_input search', className);
    return (
        <>
            <input
                type={type}
                id={id}
                className={tableClass}
                onClick={onClick}
                onKeyUp={onKeyUp}
                readOnly={readOnly}
                {...register}
            />
            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
            <B.Search onClick={iconOnClick} />
        </>
    );
}

const INPUT = {
    F: FInput,
    R: RInput,
    RS: RSearchInput,
    FS: FSearchInput,
};

export default INPUT;
