import { UseFormRegisterReturn } from 'react-hook-form';
import { generateClass } from '@common/component.utils';
import ErrorMessage from '@components/ErrorMessage';
import { CommonCodeDTO } from '@server/code/code.models';

type BaseInterface = {
    name?: string;
    listClass?: string;
    labelClass?: string;
    checkboxClass?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};
type BoxingProps = {
    children: React.ReactNode;
    className?: string;
};

type BaseProps = BaseInterface & {
    checked?: boolean;
    code: CommonCodeDTO;
};

type CommonProps = BaseInterface & {
    code: CommonCodeDTO[];
};

type RegisterProps = CommonProps & {
    register: UseFormRegisterReturn;
    errorMessage?: string;
};

function CheckedBoxing({ children, className }: BoxingProps) {
    const lClass = generateClass('chk_list', className);
    return <div className={lClass}>{children}</div>;
}

function Checkbox({ name, checkboxClass, labelClass, onChange, onClick, code, checked }: BaseProps) {
    const chClass = generateClass('chk_box', checkboxClass);
    const laClass = generateClass('chk_label', labelClass);
    return (
        <label key={code.key} className={laClass} htmlFor={`id_${code.key}`}>
            <input
                className={chClass}
                id={`id_${code.key}`}
                name={name}
                onChange={onChange}
                onClick={onClick}
                type="checkbox"
                data-get-value={code.value}
                value={code.key}
                checked={checked}
            />

            <span className="chk_span">
                <span className="chk_act"></span>
            </span>
            <span className="ft14r">{code.value}</span>
        </label>
    );
}

function CCheckboxList({ name, listClass, checkboxClass, labelClass, onChange, onClick, code }: CommonProps) {
    const lClass = generateClass('chk_list', listClass);
    const chClass = generateClass('chk_box', checkboxClass);
    const laClass = generateClass('chk_label', labelClass);

    return (
        <div className={lClass}>
            {code &&
                code.map((code) => (
                    <Checkbox
                        key={code.key}
                        checkboxClass={chClass}
                        labelClass={laClass}
                        code={code}
                        name={name}
                        onChange={onChange}
                        onClick={onClick}
                    />
                ))}
        </div>
    );
}

function CRCheckbox({ listClass, checkboxClass, labelClass, onClick, code, errorMessage, register }: RegisterProps) {
    const lClass = generateClass('chk_list', listClass);
    const laClass = generateClass('chk_label', labelClass);
    const chClass = generateClass('chk_box', checkboxClass);

    return (
        <>
            <div className={lClass}>
                {code &&
                    code.map((code) => (
                        <label key={code.key} className={laClass} htmlFor={`id_${code.key}`}>
                            <input
                                className={chClass}
                                id={`id_${code.key}`}
                                onClick={onClick}
                                type="checkbox"
                                defaultValue={code.key}
                                data-get-value={code.value}
                                {...register}
                            />

                            <span className="chk_span">
                                <span className="chk_act"></span>
                            </span>
                            <span className="ft14r">{code.value}</span>
                        </label>
                    ))}
            </div>
            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
        </>
    );
}

const Check = {
    CheckedBoxing,
    CCheckbox: Checkbox,
    CCheckboxList,
    CRCheckbox,
};

export default Check;
