import { UseFormRegisterReturn } from 'react-hook-form';
import { generateClass } from '@common/component.utils';
import ErrorMessage from '@components/ErrorMessage';
import { CommonCodeDTO } from '@server/code/code.models';

type CommonProps = {
    type: 'ALL' | 'SELECTED' | 'EMPTY';
    id?: string;
    name?: string;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLSelectElement, MouseEvent>) => void;
    code?: CommonCodeDTO[];
    value?: string | number;
};

type RegisterProps = CommonProps & {
    register: UseFormRegisterReturn;
    errorMessage?: string;
};

function FCombo({ type, id, name, className, onChange, onClick, code, value }: CommonProps) {
    const tableClass = generateClass('com_select', className);
    return (
        <select className={tableClass} id={id} name={name} onChange={onChange} onClick={onClick} value={value}>
            {type === 'ALL' ? (
                <option value="">전체</option>
            ) : type === 'SELECTED' ? (
                <option value="">선택</option>
            ) : (
                <></>
            )}

            {code &&
                code.map((c) => (
                    <option key={c.key} value={c.key}>
                        {c.value}
                    </option>
                ))}
        </select>
    );
}

function RCombo({ type, id, className, code, onClick, register, errorMessage }: RegisterProps) {
    const tableClass = generateClass('com_select', className);

    return (
        <>
            <select id={id} className={tableClass} onClick={onClick} {...register}>
                {type === 'ALL' ? (
                    <option value="">전체</option>
                ) : type === 'SELECTED' ? (
                    <option value="">선택</option>
                ) : (
                    <></>
                )}

                {code &&
                    code.map((c) => (
                        <option key={c.key} value={c.key}>
                            {c.value}
                        </option>
                    ))}
            </select>
            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
        </>
    );
}

const COMBO = {
    F: FCombo,
    R: RCombo,
};

export default COMBO;
