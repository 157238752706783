import { getMonth, getYear } from 'date-fns';
import { ko } from 'date-fns/locale';
import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ClientOnly } from 'remix-utils/client-only';
import 'react-datepicker/dist/react-datepicker.css';
import '@assets/css/common/dtpicker.scss';
import icDate from '@assets/images/ic_date.svg';
import pickerLeft from '@assets/images/picker_left.svg';
import pickerRight from '@assets/images/picker_right.svg';
import { createFilledArray } from '@common/common.utils';

type DTPickerFieldProps = {
    name: string;
    value: Date | null;
    placeholder?: string;
    onChange: (date: Date | null) => void;
    startYears?: number;
    type?: 'BUTTON' | 'INPUT';
};

type HeaderPickerProps = {
    date: Date;
    changeYear: (year: number) => void;
    changeMonth: (month: number) => void;
    decreaseMonth: () => void;
    increaseMonth: () => void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
    startYears: number;
};

function DTPicker({ name, value, onChange, placeholder, startYears = 1920, type = 'INPUT' }: DTPickerFieldProps) {
    const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <input
            className={'com_input'}
            name={name}
            value={value}
            onClick={onClick}
            readOnly={true}
            placeholder={placeholder}
            ref={ref}
        />
    ));

    const CustomButton = forwardRef(({ value, onClick }: any, ref: any) => (
        <button
            className={'com_btn'}
            name={name}
            value={value}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            ref={ref}
        />
    ));

    CustomInput.displayName = 'CustomInput';
    CustomButton.displayName = 'CustomButton';

    return (
        <ClientOnly>
            {() => (
                <ReactDatePicker
                    placeholderText={placeholder || ''}
                    name={name}
                    selected={value}
                    showIcon
                    icon={<img src={icDate} alt={'date images'} className="no-pointer-events" />}
                    isClearable
                    locale={ko}
                    //minDate={new Date()} // 최소 날짜 이전 비활성화 기준
                    //showDisabledMonthNavigation // 최소날 짜 이전 비활성화 옵션
                    onChange={onChange}
                    dateFormat={'yyyy-MM-dd'}
                    customInput={type === 'INPUT' ? <CustomInput /> : <CustomButton />}
                    disabledKeyboardNavigation
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <HeaderPicker
                            date={date}
                            changeYear={changeYear}
                            changeMonth={changeMonth}
                            decreaseMonth={decreaseMonth}
                            increaseMonth={increaseMonth}
                            prevMonthButtonDisabled={prevMonthButtonDisabled}
                            nextMonthButtonDisabled={nextMonthButtonDisabled}
                            startYears={startYears}
                        />
                    )}
                />
            )}
        </ClientOnly>
    );
}

function HeaderPicker({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    startYears,
}: HeaderPickerProps) {
    const years = createFilledArray(startYears, new Date().getFullYear()) as number[];
    const months = createFilledArray(1, 12, '월') as string[];

    return (
        <div className={'datepicker__header'}>
            <button
                type="button"
                className={'datepicker__arrow'}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            >
                <img src={pickerLeft} alt={'arrow left'} />
            </button>
            <select
                className={'datepicker__year__selectbox'}
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                className={'datepicker__month__selectbox'}
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button
                type="button"
                className={'datepicker__arrow'}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                <img src={pickerRight} alt={'arrow right'} />
            </button>
        </div>
    );
}

export default DTPicker;
