import Picker from '@components/dtpicker';
import Check from '@components/input/Checkbox';
import COMBO from '@components/input/Combo';
import INPUT from '@components/input/Input';
import TextArea from '@components/input/TextArea';
import USED from '@components/input/UsedList';

const Tag = {
    Used: {
        R: USED.R,
        F: USED.F,
    },
    Input: {
        RS: INPUT.RS,
        FS: INPUT.FS,
        R: INPUT.R,
        F: INPUT.F,
    },
    Picker: {
        R: Picker.R,
        F: Picker.F,
    },
    Select: {
        R: COMBO.R,
        F: COMBO.F,
    },
    Check: {
        Box: Check.CheckedBoxing,
        R: Check.CRCheckbox,
        F: {
            List: Check.CCheckboxList,
            Checkbox: Check.CCheckbox,
        },
    },
    Text: {
        R: TextArea.R,
        F: TextArea.F,
    },
};

export default Tag;
