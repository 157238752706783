import { FieldValues, useController } from 'react-hook-form';
import { dateToString, stringToDate } from '@common/tranfer.utils';
import DTPicker from '@components/dtpicker/DTPicker';
import ErrorMessage from '@components/ErrorMessage';
import { TControl } from '@server/models/common.types';

type RPickerProps<T extends FieldValues> = TControl<T> & {
    errorMessage?: string;
    placeholder?: string;
    startYears?: number;
    type?: 'BUTTON' | 'INPUT';
};

type FPickerProps = {
    name?: string;
    value?: string;
    errorMessage?: string;
    placeholder?: string;
    startYears?: number;
    onChange: (date: string) => void;
    type?: 'BUTTON' | 'INPUT';
};

export function RPicker<T extends FieldValues>({
    control,
    name,
    rules,
    errorMessage,
    placeholder,
    startYears = 1920,
    type = 'INPUT',
}: RPickerProps<T>) {
    const {
        field: { onChange, value },
        fieldState,
        formState,
    } = useController({
        control: control,
        name: name,
        rules: rules,
    });

    return (
        <>
            <DTPicker
                name={name}
                value={value ? value : null}
                placeholder={placeholder}
                onChange={(date) => {
                    onChange(dateToString(date));
                }}
                startYears={startYears}
                type={type}
            />
            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
        </>
    );
}

export function FPicker({
    name = 'basePickerName',
    value,
    placeholder,
    startYears = 1920,
    onChange,
    type = 'INPUT',
}: FPickerProps) {
    return (
        <>
            <DTPicker
                name={name}
                value={value ? stringToDate(value) : null}
                placeholder={placeholder}
                onChange={(date) => {
                    onChange(dateToString(date));
                }}
                startYears={startYears}
                type={type}
            />
        </>
    );
}
