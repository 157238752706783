import { UseFormRegisterReturn } from 'react-hook-form';

type UsedListProps = {
    register: UseFormRegisterReturn;
};

function RUsedList({ register }: UsedListProps) {
    return (
        <div className="chk_list">
            <label className="chk_label w15">
                <input className="chk_box" type="radio" {...register} defaultValue="Y" />
                <span className="chk_span">
                    <span className="chk_act"></span>
                </span>
                <span className="ft14r">사용</span>
            </label>
            <label className="chk_label w15">
                <input className="chk_box" type="radio" {...register} defaultValue="N" />
                <span className="chk_span">
                    <span className="chk_act"></span>
                </span>
                <span className="ft14r">미사용</span>
            </label>
        </div>
    );
}

function FUsedList() {
    return (
        <div className="chk_list">
            <label className="chk_label w15">
                <input className="chk_box" type="radio" defaultValue="Y" />
                <span className="chk_span">
                    <span className="chk_act"></span>
                </span>
                <span className="ft14r">사용</span>
            </label>
            <label className="chk_label w15">
                <input className="chk_box" type="radio" defaultValue="N" />
                <span className="chk_span">
                    <span className="chk_act"></span>
                </span>
                <span className="ft14r">미사용</span>
            </label>
        </div>
    );
}

const USED = {
    R: RUsedList,
    F: FUsedList,
};

export default USED;
