import { UseFormRegisterReturn } from 'react-hook-form';
import { generateClass } from '@common/component.utils';
import ErrorMessage from '@components/ErrorMessage';

type CommonProps = {
    id?: string;
    name?: string;
    className?: string;
    readOnly?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
    placeholder?: string;
};

type RegisterProps = CommonProps & {
    register: UseFormRegisterReturn;
    errorMessage?: string;
};

function FText({ id, name, className, onChange, onClick, readOnly = false, placeholder }: CommonProps) {
    const tableClass = generateClass('com_area form_area', className);
    return (
        <textarea
            className={tableClass}
            id={id}
            onChange={onChange}
            name={name}
            onClick={onClick}
            readOnly={readOnly}
            placeholder={placeholder}
        ></textarea>
    );
}

function RText({ id, className, onClick, register, errorMessage, readOnly = false }: RegisterProps) {
    const tableClass = generateClass('com_area form_area', className);
    return (
        <>
            <textarea className={tableClass} id={id} onClick={onClick} readOnly={readOnly} {...register}></textarea>

            {errorMessage && <ErrorMessage message={errorMessage}></ErrorMessage>}
        </>
    );
}

const TextArea = {
    F: FText,
    R: RText,
};

export default TextArea;
